import useColorWrapper from '../colorWrapper/useColorWrapper';
import StyledTextTooltip from './StyledTextTooltip';
interface IGenericTooltipProps {
  text: string | React.ReactNode;
  children: React.ReactElement<any, any>;
  disableHoverListener?: boolean;
  className?: string;
  bgColor?: ColorType;
  color?: ColorType;
  isOpen?: boolean;
  disabled?: boolean;
  placement?: 'top' | 'top-start';
}

function GenericTooltip({
  children,
  text,
  disableHoverListener,
  className,
  bgColor = 'DARK_PRIMARY',
  color = 'WHITE',
  isOpen,
  disabled = false,
  placement = 'top',
}: IGenericTooltipProps) {
  const classesCustom = useColorWrapper({
    colorWrapperType: 'tooltip',
    bgColor,
    color,
  });

  return (
    <StyledTextTooltip
      disableFocusListener
      disableTouchListener
      disableHoverListener={disableHoverListener}
      title={disabled ? '' : text}
      open={isOpen}
      placement={placement}
      arrow
      className={classesCustom}
    >
      <div>{children}</div>
    </StyledTextTooltip>
  );
}

export default GenericTooltip;
