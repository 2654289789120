import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';

const StyledTextTooltip = styled(({ className, ...props }: TooltipProps) => {
  return <Tooltip {...props} classes={{ popper: className }} />;
})(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: '4px',
    padding: '4px 8px',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '14px',
    maxWidth: 'unset',
    whiteSpace: 'normal',
  },
  [`&[data-popper-placement='top'] .${tooltipClasses.tooltip}`]: {
    marginBottom: '6px !important', // Add a bottom margin if Tooltip is on top
  },
  [`&[data-popper-placement='bottom'] .${tooltipClasses.tooltip}`]: {
    marginTop: '6px !important', // Add top margin if Tooltip is at bottom
  },
}));

export default StyledTextTooltip;
