import styles from './tickSquare.module.scss';
interface IProps {
  color: 'Green' | 'Grey' | 'Red';
  width?: number;
  height?: number;
  isCross?: boolean;
}

function TickSquare({ color, height = 22, width = 22, isCross }: IProps) {
  let css = '';

  if (color === 'Green') css = styles.tickGreen;
  if (color === 'Grey') css = styles.tickGrey;
  if (color === 'Red') css = styles.tickRed;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/*  background (cercle) */}
      <path
        className={css}
        d="M14.8408 1.83337L7.15909 1.83337C3.82242 1.83337 1.83325 3.82254 1.83325 7.15921L1.83325 14.8317C1.83325 18.1775 3.82242 20.1667 7.15909 20.1667H14.8316C18.1683 20.1667 20.1574 18.1775 20.1574 14.8409V7.15921C20.1666 3.82254 18.1774 1.83337 14.8408 1.83337Z"
      />

      {/* Pattern check or cross with animation */}
      <path
        d={isCross ? 'M6 6 L16 16 M6 16 L16 6' : 'M6 10 L10 14 L16 7'}
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
}

export default TickSquare;
