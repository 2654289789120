import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';
interface IPasswordAdornmentProps {
  show: boolean;
  onEyeClick: () => void;
}
function PasswordAdornment({ show, onEyeClick }: IPasswordAdornmentProps) {
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={onEyeClick}
        onMouseDown={handleMouseDownPassword}
      >
        {show ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  );
}

export default PasswordAdornment;
