import { nanoid } from '@reduxjs/toolkit';
import TickSquare from '../../../../../features/externalPdf/shared/components/tickSquare/TickSquare';
import CrossIcon from '../../../../../shared/components/icons/CrossIcon';
import { removeDuplicates } from '../../../../../shared/utils/arrayTools';
import styles from './customPasswordMessage.module.scss';

type Line = { check: RegExp; rule: string; reverse?: boolean };
type Lines = Line[];

const lines = (value: string): Lines => {
  const regex = /[^a-zA-Z\d-_&!?#*$]/g;
  const r = value.match(regex);
  const distinct = r ? removeDuplicates(r) : null;

  return [
    { check: /.*[a-z].*/, rule: 'Au moins une minuscule' },
    { check: /.*[A-Z].*/, rule: 'Au moins une majuscule' },
    { check: /.*[0-9].*/, rule: 'Au moins un chiffre' },
    { check: /^.{8,}$/, rule: 'Au moins 8 caractères' },
    {
      check: /^(?=.*[-_&!?#*$]).*$/,
      rule: 'Au moins un des caractères suivant -_&!?#*$',
    },
    {
      check: regex,
      reverse: true,
      rule: `Caractère(s) invalide(s) : ${distinct ? distinct.join(' ') : 'Aucun'}`,
    },
  ];
};
interface ICustomPasswordMessageProps {
  value: string;
  onResetError?: () => void;
}

function CustomPasswordMessage({
  value,
  onResetError,
}: ICustomPasswordMessageProps) {
  return (
    <div className={styles.dynamicErrorMessageContainer}>
      <CrossIcon
        onClick={onResetError}
        bgColor="WHITE"
        color="PRIMARY"
        noPadding
        height={20}
        width={20}
        className={styles.crossIcon}
      />
      <p>Le mot de passe doit comporter : </p>
      <ul>
        {lines(value).map((m: Line) => {
          const isOk = m.check.test(value);
          const realOk = m.reverse ? !isOk : isOk;

          return (
            <li key={nanoid()}>
              <div className={styles.tickSquareContainer}>
                <TickSquare
                  color={realOk ? 'Green' : 'Red'}
                  height={18}
                  width={18}
                  isCross={!isOk}
                />
              </div>
              <span className={realOk ? '' : styles.error}>{m.rule}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default CustomPasswordMessage;
