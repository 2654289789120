// Import style
import { useAppSelector } from '../../../App/store';
import { getAuthState } from '../../../features/auth/authSlice';
import PasswordForm from '../../../shared/components/formComponents/updatePasswordForm/UpdatePasswordForm';

import styles from './passwordTab.module.scss';

function PasswordTab(): JSX.Element {
  const { user } = useAppSelector(getAuthState);

  return (
    <div className={styles.passwordTab}>
      <h1>Modifier mon mot de passe</h1>

      <div className={styles.formContainer}>
        <PasswordForm user={user!} />
      </div>
    </div>
  );
}

export default PasswordTab;
