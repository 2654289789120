import { TextField } from '@mui/material';
import { ChangeEvent, ClipboardEvent, FocusEvent, useState } from 'react';
import GenericTooltip from '../../../../shared/components/tooltips/GenericTooltip';
import CustomPasswordMessage from './customPasswordMessage/CustomPasswordMessage';
import styles from './fieldInputPwd.module.scss';
import { IInputPasswordTypeBase } from './FieldInputPwdFormCustom';
import PasswordAdornment from './PasswordAdornment';
interface IPasswordInputCustomProps extends IInputPasswordTypeBase {
  value: string;
  error?: string;
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onResetError?: () => void;
}

function PasswordInputCustom({
  value,
  isLoginForm,
  disabled,
  error,
  placeholder,
  readOnly,
  onFocus,
  required,
  dataCy,
  InputProps,
  className,
  onChange,
  onResetError,
  hasComplexErrorMessage,
  errorTooltipPlacment,
}: IPasswordInputCustomProps) {
  // **** local states ****
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleCopyCutPast = (e: ClipboardEvent<HTMLDivElement>) => {
    if (!isLoginForm) {
      e.preventDefault();
    }
  };

  const handleFocus = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => {
    if (onFocus) {
      onFocus(e);
    }
  };

  return (
    <GenericTooltip
      text={hasComplexErrorMessage ? null : error}
      bgColor="ERROR"
      color="WHITE"
      isOpen
      placement={errorTooltipPlacment as any}
    >
      <div className={styles.pwdErrorFieldContainer}>
        <TextField
          value={value}
          onChange={onChange}
          type={showPassword ? 'text' : 'password'}
          disabled={disabled}
          variant="outlined"
          error={Boolean(error)}
          placeholder={placeholder}
          className={className}
          onFocus={handleFocus}
          onCut={handleCopyCutPast}
          onCopy={handleCopyCutPast}
          onPaste={handleCopyCutPast}
          InputProps={{
            classes: {
              root: styles.root,
              input: [styles.input, !isLoginForm ? styles.bigSize : ''].join(' '),
              error: styles.errorOutline,
              inputAdornedEnd: styles.adornmentEnd,
            },
            endAdornment: (
              <PasswordAdornment
                show={showPassword}
                onEyeClick={handleClickShowPassword}
              />
            ),
            readOnly,
            ...InputProps,
          }}
          FormHelperTextProps={{
            className: styles.errorText, // style for helper text or error text
          }}
          required={required}
          data-cy={dataCy}
        />
        {hasComplexErrorMessage && error ? (
          <CustomPasswordMessage value={value} onResetError={onResetError} />
        ) : null}
      </div>
    </GenericTooltip>
  );
}

export default PasswordInputCustom;
