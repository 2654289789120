import { ChangeEvent, FocusEvent } from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import PasswordInputCustom from './PasswordInputCustom';

export interface IInputPasswordTypeBase {
  isLoginForm?: boolean;
  label?: string | null;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  readOnly?: boolean;
  required?: boolean;
  dataCy?: string;
  InputProps?: any;
  onFocus?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
  onResetError?: () => void;
  hasComplexErrorMessage?: boolean;
  errorTooltipPlacment?:
    | 'top'
    | 'top-start'
    | 'bottom'
    | 'left'
    | 'right'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end';
}
interface IFieldInputPwdFormCustomProps extends IInputPasswordTypeBase {
  name: string;
  errorTooltip?: FieldError;
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export default function FieldInputPwdFormCustom(
  props: IFieldInputPwdFormCustomProps
) {
  const { name } = props;

  // **** context ****
  const {
    control,
    formState: { errors },
    setValue,
    clearErrors,
    trigger,
  } = useFormContext();

  // **** handlers ****
  const handleChange = (
    name: string,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = event.target.value;

    setValue(name, value);
    trigger(name);
    clearErrors();
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value } }) => (
        <PasswordInputCustom
          {...props}
          value={value}
          onChange={(e) => handleChange(name, e)}
          error={errors[name]?.message as string}
        />
      )}
    />
  );
}
