import { useAppSelector } from '../../App/store';
import SubscriptionLimitModal from '../company/subscription/SubscriptionLimitModal';
import AddUserModal from '../users/components/AddUserModal/AddUserModal';
import AddUserResultModal from '../users/components/AddUserModal/AddUserResultModal';
import PasswordChangeResultModal from '../users/components/PasswordChangeResultModal';
import { getModalsState } from './modalsSlice';

function MyAccountModalContainer() {
  const { addUser, addUserResult, passwordChangeResult, subscriptionLimit } =
    useAppSelector(getModalsState);

  return (
    <>
      {addUser && <AddUserModal />}
      {addUserResult.isOpen && <AddUserResultModal />}
      {passwordChangeResult && <PasswordChangeResultModal />}
      {subscriptionLimit && <SubscriptionLimitModal />}
    </>
  );
}

export default MyAccountModalContainer;
