import ItemActions from '../../../components/folderAndSectorActions/ItemActions';
import GenericTooltip from '../../../shared/components/tooltips/GenericTooltip';
import {
  DASHBOARD_PROSPECTION_ENTITY_NAME_LENGTH,
  LEFT_PANEL_PROSPECTION_ENTITY_NAME_LENGTH,
} from '../../../shared/constants';
import { stringCut } from '../../../shared/utils/stringFunctions';
import LeftPanelFolderIndex from './LeftPanelFolderIndex';
import styles from './folder.module.scss';

interface IFolderHeaderProps {
  folder: IFolder;
  isDashboard?: boolean;
  deployedFolder?: nullable<IFolder>;
  deployedSubFolder?: nullable<IFolder>;
  isSubfolder?: boolean;
}

export default function FolderHeader({
  folder,
  isDashboard,
  deployedFolder,
  deployedSubFolder,
  isSubfolder,
}: IFolderHeaderProps) {
  return (
    <div
      className={`${styles.folderHeader} ${folder.parent ? styles.subfolder : ''} `}
      style={{ borderLeft: '4px solid ' + folder.markerColor }}
      data-cy="folder-header"
    >
      {isSubfolder && <div className={styles.headerLibelle}>Sous-dossier</div>}

      {!isDashboard && <LeftPanelFolderIndex folder={folder} />}

      <div className={styles.titleContainer}>
        <GenericTooltip
          text={folder.name}
          placement="top-start"
          className={styles.title}
        >
          <h3>
            {stringCut(
              folder.name,
              isDashboard
                ? DASHBOARD_PROSPECTION_ENTITY_NAME_LENGTH
                : LEFT_PANEL_PROSPECTION_ENTITY_NAME_LENGTH
            )}
          </h3>
        </GenericTooltip>

        {!folder.parent ? (
          <div className={styles.subCount}>{`${folder.subCount} sous-dossier${
            folder?.subCount ?? 0 > 1 ? 's' : ''
          }, ${folder.plotStudiesCount} parcelle${
            folder.plotStudiesCount > 1 ? 's' : ''
          }`}</div>
        ) : (
          <div className={styles.subCount}>{`${folder.plotStudiesCount} parcelle${
            folder.plotStudiesCount > 1 ? 's' : ''
          }`}</div>
        )}
      </div>

      <div
        className={`${styles.iconContainer} ${isDashboard && styles.isDashboard}`}
      >
        <ItemActions
          type="folder"
          item={folder as IFolderSector<IFolder>}
          isOpen={
            folder.idIri === deployedSubFolder?.idIri ||
            folder.idIri === deployedFolder?.idIri
          }
          isDashboard={isDashboard}
          canOpen={folder.subCount > 0 || folder.plotStudiesCount > 0}
        />
      </div>
    </div>
  );
}
