import { appActions } from '../../../App/appSlice';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import SupportEmailLink from '../../../components/Common/SupportEmailLink';
import GenericModal, {
  GenericModalActionEnum,
  GenericModalEnum,
} from '../../../components/Modals/GenericModal';
import { APIStatus } from '../../../services/axiosFiles/apiTypes';
import { logoutThunk } from '../../auth/authThunks';
import IndividualCircularLoader from '../../loaders/individualCircularLoader';
import { modalsActions } from '../../modals/modalsSlice';
import { getUsersState, userActions } from '../usersSlice';

function PasswordChangeResultModal() {
  const { isEditingUser, apiStatus } = useAppSelector(getUsersState);
  const dispatch = useAppDispatch();

  const ERROR = apiStatus === APIStatus.REJECTED;
  const PENDING = apiStatus === APIStatus.PENDING;

  const handleCloseModal = () => {
    if (isEditingUser) {
      dispatch(modalsActions.passwordChangeResult(false));
      dispatch(userActions.setEditingUser(false));
      if (ERROR) {
        dispatch(userActions.resetApiError());
      }
    } else {
      if (!ERROR) {
        dispatch(appActions.setAccountTab('abonnement'));
        dispatch(logoutThunk());
      } else {
        dispatch(modalsActions.passwordChangeResult(false));
      }
    }
  };

  return (
    <GenericModal
      closeModal={handleCloseModal}
      modalType={
        PENDING
          ? GenericModalEnum.INFO
          : ERROR
            ? GenericModalEnum.ERROR
            : GenericModalEnum.CONFIRMATION
      }
      actionType={PENDING ? GenericModalActionEnum.NONE : GenericModalActionEnum.OK}
      okButtonContent="OK"
      okCallback={handleCloseModal}
      title="Changement de mot de passe"
      hasIcon={!PENDING}
    >
      {PENDING ? (
        <IndividualCircularLoader size={100} />
      ) : !ERROR ? (
        <>
          {isEditingUser ? (
            <p>Le mot de passe a été modifié avec succcès.</p>
          ) : (
            <>
              <p>Votre mot de passe a été modifié avec succès.</p>
              <p>Vous allez être déconnecté.</p>
            </>
          )}
        </>
      ) : (
        <>
          <p>
            Une erreur est survenue et votre mot de passe n&#039;a pas pu être
            modifié.
          </p>
          <p>
            Veuillez réessayer ultérieurement ou contacter notre support{' '}
            <SupportEmailLink />
          </p>
        </>
      )}
    </GenericModal>
  );
}

export default PasswordChangeResultModal;
