import { nanoid } from '@reduxjs/toolkit';
import { useAppDispatch } from '../../../../App/store';
import { dateFormat } from '../../../../lib/formats/dataFormat';
import { modalsActions } from '../../../modals/modalsSlice';
import styles from './susbscriptionCard.module.scss';
interface ISubscriptionCardProps {
  subscription: Subscription;
}

export default function SubscriptionCard({
  subscription,
}: ISubscriptionCardProps): JSX.Element {
  const { cities, cityCount, departments, departmentCount } = subscription;
  const dispatch = useAppDispatch();
  const onClickReadMoreModal = (type: ReadMoreModalType) => {
    dispatch(modalsActions.readMore({ status: true, type }));
  };

  return (
    <div className={styles.subscriptionCard}>
      <h2>{subscription.pricePerMonth} €/mois</h2>
      <div className={styles.subscriptionCardBody}>
        <div className={styles.infos}>
          <div className={styles.sec}>
            <p>Début d’abonnement</p>
            <p>
              <strong>{dateFormat(subscription.startAt)}</strong>
            </p>
          </div>
          <div className={styles.sec}>
            <p>Secteur(s) géographique(s)</p>
            <div key={nanoid()} className={styles.flexDiv}>
              <strong>
                Département(s):{' '}
                {subscription.hasFullMapAccess
                  ? ' Tous'
                  : departments.result &&
                    departments.result
                      ?.slice(0, 5)
                      .map((d) => d.departmentCode)
                      .join(', ')}
              </strong>
              {!subscription.hasFullMapAccess &&
                departments.result &&
                departmentCount > 5 && (
                  <span className={styles.readMore}>
                    <p onClick={() => onClickReadMoreModal('departments')}>...</p>
                  </span>
                )}
            </div>
            <div key={nanoid()} className={styles.flexDiv}>
              <strong className={styles.cityName}>
                Ville<span>(s)</span> supplémentaire<span>(s)</span>:{' '}
                {!subscription.hasFullMapAccess &&
                  cities.result
                    ?.slice(0, 5)
                    .map((city) => city.name)
                    .join(', ')}
              </strong>
              {!subscription.hasFullMapAccess && cities.result && cityCount > 5 && (
                <span className={styles.readMore}>
                  <p onClick={() => onClickReadMoreModal('cities')}>...</p>
                </span>
              )}
            </div>
          </div>
        </div>
        <div>
          <ul>
            {subscription.hasFullMapAccess && (
              <li>A un accès complet à la France métropolitaine + Corse.</li>
            )}
            {(subscription.hasFullMapAccess || subscription.hasDomMapAccess) && (
              <li>A un accès complet aux Départements d’outre-mer.</li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}
