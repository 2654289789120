import { useNavigate } from 'react-router-dom';
import { getAppState } from '../App/appSlice';
import { useAppDispatch, useAppSelector } from '../App/store';
import Logo from '../assets/images/logo_urbanease_horizontal.svg';
import { ReactComponent as Logout } from '../assets/images/logout-icon.svg';
import { ReactComponent as RightArrowSmall } from '../assets/images/right-arrow-small.svg';
import { ReactComponent as SwitchAccount } from '../assets/images/switch-account-icon.svg';
import AccountTabs from '../components/Account/AccountTabs';
import DropdownMenu from '../components/Account/DropdownMenu/DropdownMenu';
import PasswordTab from '../components/Account/PasswordTab/PasswordTab';
import SubscriptionTab from '../components/Account/SubscriptionTab';
import TeamTab from '../components/Account/TeamTab/TeamTab';
import UrbaneaseAcademyTab from '../components/Account/UrbaneaseAcademyTab/UrbaneaseAcademyTab';
import WarningRDVTab from '../components/Account/WarningRDVTab';
import { getAuthState } from '../features/auth/authSlice';
import { logoutThunk } from '../features/auth/authThunks';
import useCompany from '../features/company/useCompany';
import MailshotAccountTab from '../features/mailshots/components/MailshotAccountTabs';
import {
  getMailshotsState,
  mailshotsActions,
} from '../features/mailshots/mailshotsSlice';
import { modalsActions } from '../features/modals/modalsSlice';
import MyAccountModalContainer from '../features/modals/MyAccountModalContainer';
import styles from './styles/MyAccountPage.module.scss';

const MyAccountPage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user, isManager, isLogged } = useAppSelector(getAuthState);
  const { getCompanyName, companies } = useCompany();
  const { accountTab } = useAppSelector(getAppState);
  const { updatingQuill } = useAppSelector(getMailshotsState);

  const handleQuillConfirm = (value: AccountBannerActionsType) => {
    dispatch(
      mailshotsActions.setQuillDatasBeforeNavigation({
        content: null,
        bannerTab: value,
      })
    );
  };

  const handleRedirection = () => {
    if (updatingQuill) {
      handleQuillConfirm('goToHome');
    } else {
      navigate('/');
    }
  };

  const handleLogout = () => {
    if (updatingQuill) {
      handleQuillConfirm('logout');
    } else {
      dispatch(logoutThunk());
      navigate('/');
    }
  };

  const handleChangeCompany = () => {
    if (updatingQuill) {
      handleQuillConfirm('companyChange');
    } else {
      dispatch(modalsActions.companyChange(true));
    }
  };

  const dropdownMenuItems = [
    {
      label: 'Changer de compte',
      icon: <SwitchAccount />,
      onClick: handleChangeCompany,
      visible: (companies?.length ?? 0) > 1,
    },
    {
      label: 'Déconnexion',
      icon: <Logout />,
      onClick: handleLogout,
      visible: true,
      dataCy: 'account-dropdown-logout',
    },
  ];

  return (
    <div className={styles.account}>
      <div className={styles.mainWrapper} data-cy="account-page">
        {/* Header */}
        <div className={styles.header}>
          <div className={styles.headerColLeft}>
            <img src={Logo} alt="logo" onClick={handleRedirection} />
          </div>
          <div className={styles.headerColRight}>
            <button
              onClick={handleRedirection}
              className={styles.goBack}
              data-cy="account-banner-back-to-home"
            >
              Retour sur Urbanease <RightArrowSmall />
            </button>

            <div>
              <p className={styles.user}>
                {user?.lastName} {user?.firstName}
              </p>
              <p className={styles.badge}>
                {`${getCompanyName()?.name} ${
                  getCompanyName()?.postalCode
                    ? `(${getCompanyName()?.postalCode})`
                    : ''
                }`}
              </p>
            </div>

            <DropdownMenu items={dropdownMenuItems} />
          </div>
        </div>
        {/* Body */}
        <div className={styles.body}>
          <AccountTabs selectedTab={accountTab} />
          {isLogged && (
            <div className={styles.bodyWrapper}>
              {accountTab === 'abonnement' && <SubscriptionTab />}
              {isManager && accountTab === 'team' && <TeamTab />}
              {isManager && accountTab === 'mailshots' && <MailshotAccountTab />}
              {accountTab === 'pwd_change' && <PasswordTab />}
              {accountTab === 'faq' && <UrbaneaseAcademyTab />}
              {accountTab === 'rdv' && <WarningRDVTab />}
            </div>
          )}
        </div>
        {/* Footer */}
        <div className={styles.footer}>
          <div className={styles.innerFooter}>
            <h3>Une question? Besoin d’aide?</h3>
            <div className={styles.innerPara}>
              Notre équipe est là pour vous aider
            </div>
            <a href="mailto:support@urbanease.io">
              <button>CONTACTEZ-NOUS</button>
            </a>
            <a
              className={styles.legalMentionsButton}
              href="https://urbanease.s3.amazonaws.com/CGV%20et%20RGPD%20Urbanease.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              CGU et mentions légales
            </a>
          </div>
        </div>
        {/* Modals */}
        <MyAccountModalContainer />
      </div>
    </div>
  );
};

export default MyAccountPage;
