import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import { isEmpty } from 'lodash';
import { memo, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../../App/store';
import SubmitButton from '../../../../../components/RightPanelModal/TabsContent/bodys/components/prospection/SubmitButton';

import ContactFormComponents from '../../../../contacts/contactForm/ContactFormComponents';
import {
  contactFormHydratation,
  initialContactForm,
} from '../../../../contacts/contactForm/contactFormValidation';
import {
  contactsActions,
  getContactsState,
} from '../../../../contacts/contactsSlice';
import { getMapState } from '../../../../map/mapSlice';
import { modalsActions } from '../../../../modals/modalsSlice';
import createStudyContactThunk from '../../../services/thunks/createStudyContactThunk';
import updateStudyContactThunk from '../../../services/thunks/updateStudyContactThunk';
import { getStudyState, studyActions } from '../../../slices/studySlice';
import ContactRoleSelect from './ContactRoleSelect';
import styles from './contact.module.scss';
import { studyContactValidationSchema } from './studyContactValidation';

interface IProspectionFormProps {
  closeForm?: () => void;
}

function StudyContactForm({ closeForm }: IProspectionFormProps) {
  const { studyContactToUpdate } = useAppSelector(getStudyState);
  const { geolocDatas } = useAppSelector(getMapState);
  const { contactForAction } = useAppSelector(getContactsState);
  // Regex to extract the address part
  const regex = /^(.*?)(?=\s\d{5}\b)/;
  // Match the address part
  const match = geolocDatas?.address.match(regex);

  const formOptions = useForm<StudyContactForm>({
    defaultValues: {
      ...initialContactForm,
      postalCode: geolocDatas?.postalCode ?? '',
      addressStreet: geolocDatas?.address ? (match ? match[1] : '') : '',
      city: geolocDatas?.city ?? '',
      country: 'France',
    },
    resolver: yupResolver(studyContactValidationSchema),
  });
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = formOptions;

  useEffect(() => {
    if (studyContactToUpdate) {
      contactFormHydratation(studyContactToUpdate.contact, setValue);
      setValue('roleIdIri', studyContactToUpdate.role?.idIri ?? '');
    }
  }, [studyContactToUpdate]);

  useEffect(() => {
    if (contactForAction) {
      contactFormHydratation(contactForAction, setValue);
      setValue('roleIdIri', '');
    }
  }, [contactForAction]);

  const handleCloseForm = () => {
    // reset form
    reset();

    // reset memorized contacts
    dispatch(contactsActions.contactForActionSet(null));
    dispatch(studyActions.setStudyContactToUpdate(null));

    // shut down the form
    closeForm?.();
  };

  useEffect(() => {
    return () => {
      handleCloseForm();
    };
  }, []);

  const handleResearchContactClick = () => {
    dispatch(contactsActions.contactsReset());
    dispatch(modalsActions.contactSearch(true));
  };

  const handleFormSubmit = (values: StudyContactForm) => {
    if (isEmpty(errors)) {
      if (studyContactToUpdate) {
        dispatch(updateStudyContactThunk({ body: values, studyContactToUpdate }));
        closeForm?.();
      } else {
        dispatch(
          createStudyContactThunk({ body: values, contact: contactForAction })
        );
        closeForm?.();
      }
    }
  };

  return (
    <>
      <Button
        className={styles.researchContactBtn}
        onClick={handleResearchContactClick}
      >
        Rechercher un contact
      </Button>
      <FormProvider {...formOptions}>
        <form
          className={styles.contactForm}
          id="contact-form"
          onSubmit={handleSubmit(handleFormSubmit)}
          noValidate
        >
          <ContactFormComponents
            addressOld={studyContactToUpdate?.contact.addressOld}
            extended
          />

          <div className={styles.centerBlock}>
            <ContactRoleSelect
              name="roleIdIri"
              displayEmpty
              noValue="Rôle"
              dataCy="study-contact-form-role"
            />
          </div>

          <SubmitButton
            label={
              !contactForAction && !studyContactToUpdate
                ? 'Créer un contact'
                : 'Modifier un contact'
            }
            dataCy="submit-button-component"
          />
        </form>
      </FormProvider>
    </>
  );
}

export default memo(StudyContactForm);
