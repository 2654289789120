import { useEffect } from 'react';
import { appActions } from '../../App/appSlice';
import useRightPanel from '../../App/hooks/useRightPanel';
import { useAppDispatch, useAppSelector } from '../../App/store';
import { getAuthState } from '../../features/auth/authSlice';
import {
  getMailshotsState,
  mailshotsActions,
} from '../../features/mailshots/mailshotsSlice';
import GenericTabContainer from '../../shared/components/genericTab/GenericTabContainer';
import styles from './accountTabs.module.scss';
import { AccountTabsValues } from './constant';

interface IAccountTabsProps {
  selectedTab: AccountTab;
}

const AccountTabs = ({ selectedTab }: IAccountTabsProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { isManager } = useAppSelector(getAuthState);
  const { updatingQuill } = useAppSelector(getMailshotsState);
  const { closeRightPanel, rightPanelDisplay } = useRightPanel();
  const tabLabels = AccountTabsValues.filter(
    (elt) => isManager || elt.role.includes('user')
  ).map((elt) => ({
    id: elt.id,
    name: elt.title,
  }));
  const selected = tabLabels.find((f) => f.id === selectedTab);

  const handleTabChange = (value: AccountTab) => {
    if (rightPanelDisplay) {
      closeRightPanel();
    }
    if (selectedTab === 'mailshots' && updatingQuill) {
      dispatch(
        mailshotsActions.setQuillDatasBeforeNavigation({
          accountTab: value,
          content: null,
        })
      );
    } else {
      dispatch(appActions.setAccountTab(value));
    }
  };

  useEffect(() => {
    return () => {
      closeRightPanel();
    };
  }, []);

  return (
    <div className={styles.accountTabs}>
      <GenericTabContainer
        tabs={tabLabels}
        selectedDefault={selected ?? null}
        onSelect={(entity) => handleTabChange(entity.id as AccountTab)}
        dataCyBase="account-tab"
        isAccountTabs
      />
    </div>
  );
};

export default AccountTabs;
