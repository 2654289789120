type Entity = { [key: string]: any };

export function getDuplicatesFromArray<T extends Entity>(
  arr: T[],
  property: keyof T
): T[];
export function getDuplicatesFromArray(arr: string[]): string[];
export function getDuplicatesFromArray<T extends Entity>(
  arr: T[] | string[],
  property?: keyof T
): T[] | string[] {
  const propertyCount: { [key: string]: number } = {};
  if (typeof arr[0] === 'string') {
    const duplicates: string[] = [];
    (arr as string[]).forEach((item) => {
      if (propertyCount[item]) {
        propertyCount[item]++;
      } else {
        propertyCount[item] = 1;
      }
    });

    (arr as string[]).forEach((item) => {
      if (propertyCount[item] > 1 && !duplicates.includes(item)) {
        duplicates.push(item);
      }
    });
    return duplicates;
  } else {
    const duplicates: T[] = [];
    (arr as T[]).forEach((item) => {
      const propValue = item[property as keyof T];
      if (propertyCount[propValue]) {
        propertyCount[propValue]++;
      } else {
        propertyCount[propValue] = 1;
      }
    });

    (arr as T[]).forEach((item) => {
      if (
        propertyCount[item[property as keyof T]] > 1 &&
        !duplicates.includes(item)
      ) {
        duplicates.push(item);
      }
    });
    return duplicates;
  }
}

/**
 * Filters an array by removing duplicates.
 * @param array - The input array to filter.
 * @returns A new array without duplicates.
 */
export function removeDuplicates<T>(array: T[]): T[] {
  return Array.from(new Set(array));
}
